import React, { FC, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Text } from '@mindfulchefuk/design-system/Text'
import { Box, Flex } from '@mindfulchefuk/design-system/layout'
import { Icon } from '@mindfulchefuk/design-system/Icon'
import { ResetButton } from '@mindfulchefuk/design-system/Button'
import { ColorToken } from '@mindfulchefuk/design-system/system/theme/index'

type TAccordionItemProps = {
  isFirst?: boolean
  title: string
  onClick: () => void
  titleColor: ColorToken
}

const iconVariants = {
  open: { rotate: 45, scale: 1.1 },
  closed: { rotate: 0, scale: 1 },
}

export const AccordionItem: FC<TAccordionItemProps> = ({
  children,
  title,
  isFirst,
  onClick,
  titleColor,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      borderBottomWidth={1}
      borderTopWidth={isFirst ? 1 : 0}
      borderColor="aubergine-50"
      marginTop={isFirst ? [16, 24] : 0}
      mx={[-24, 0]}
      px={[24, 0]}
      onClick={onClick}
    >
      <ResetButton
        onClick={() => setIsOpen((prevOpen) => !prevOpen)}
        fullWidth
        data-testid="accordion-item"
      >
        <Flex justify="space-between" align="center" py={16} textAlign="left">
          <Text variant="body-md-500-caps" mr={8} color={titleColor}>
            {title}
          </Text>
          <motion.div
            animate={isOpen ? 'open' : 'closed'}
            variants={iconVariants}
          >
            <Icon type="add" size={16} />
          </motion.div>
        </Flex>
      </ResetButton>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            style={{ overflow: 'hidden' }}
          >
            <Box pb={32}>{children}</Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}
