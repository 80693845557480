import { ActionCreator } from 'redux'

import { PaymentsOnboardingOrderPreview } from '@mindfulchefuk/api-client'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import createActionHelper from '@mindfulchefuk/helpers/js/createActionHelper'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import { RootState } from '@mindfulchefuk/types/store'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { postCombinedOrderPreview } from '@mindfulchefuk/services/postCombinedOrderPreview'
import get from 'lodash/get'

export const BASKET_DELIVERY_DATE_SET = 'BASKET_DELIVERY_DATE_SET'
export const setDeliveryDate = createActionHelper(BASKET_DELIVERY_DATE_SET)

export const BASKET_ITEM_ADD = 'BASKET_ITEM_ADD'
export const addItem = createActionHelper(BASKET_ITEM_ADD)

export const BASKET_ITEMS_ADD = 'BASKET_ITEMS_ADD'
export const addItems = createActionHelper(BASKET_ITEMS_ADD)

export const BASKET_DISCOUNT_APPLY = 'BASKET_DISCOUNT_APPLY'
export const applyDiscount = createActionHelper(BASKET_DISCOUNT_APPLY)

export const BASKET_SET_SHIPPING_PRICE = 'BASKET_SET_SHIPPING_PRICE'
export const setBasketShippingPrice = createActionHelper(
  BASKET_SET_SHIPPING_PRICE
)

export const BASKET_POST_LOGIN_DISCOUNT_APPLY =
  'BASKET_POST_LOGIN_DISCOUNT_APPLY'
export const postLoginApplyDiscount = createActionHelper(
  BASKET_POST_LOGIN_DISCOUNT_APPLY
)

export const BASKET_ITEM_REMOVE = 'BASKET_ITEM_REMOVE'
export const removeItem = createActionHelper(BASKET_ITEM_REMOVE)

export const BASKET_ITEMS_RESET = 'BASKET_ITEMS_RESET'
export const resetItems = createActionHelper(BASKET_ITEMS_RESET)

export const BASKET_RESET = 'BASKET_RESET'
export const resetBasket = createActionHelper(BASKET_RESET)

export const BASKET_PORTION_COUNT_SET = 'BASKET_PORTION_COUNT_SET'
export const setPortionCount = createActionHelper(BASKET_PORTION_COUNT_SET)

export const BASKET_VOUCHER_CODE_SET = 'BASKET_VOUCHER_CODE_SET'
export const setVoucherCode = createActionHelper(BASKET_VOUCHER_CODE_SET)

export const BASKET_VOUCHER_CODE_UNSET = 'BASKET_VOUCHER_CODE_UNSET'
export const unsetVoucherCode = createActionHelper(BASKET_VOUCHER_CODE_UNSET)

export const BASKET_DISCOUNT_REVOKE = 'BASKET_DISCOUNT_REVOKE'
export const revokeDiscount = createActionHelper(BASKET_DISCOUNT_REVOKE)

export const SET_BASKET_LOADING = 'SET_BASKET_LOADING'
export const setBasketLoading = createActionHelper(SET_BASKET_LOADING)

export const BASKET_MEAL_PLAN_SET = 'BASKET_MEAL_PLAN_SET'
export const setBasketMealPlan = createActionHelper(BASKET_MEAL_PLAN_SET)

export const BASKET_CLEAR_LAST_ADDED_RECIPE = 'BASKET_CLEAR_LAST_ADDED_RECIPE'
export const clearLastAddedRecipe = createActionHelper(
  BASKET_CLEAR_LAST_ADDED_RECIPE
)

export const updateBasket =
  (recipe: Recipe, action: 'add' | 'remove'): ActionCreator<void> =>
  async (dispatch) => {
    dispatch(setBasketLoading(true))

    if (action === 'add') {
      dispatch(addItem(recipe))
    } else if (action === 'remove') {
      dispatch(removeItem(recipe))
    }

    await dispatch(repopulateBasket())
    dispatch(setBasketLoading(false))
  }

export const applyVoucher = (
  code: string | undefined,
  suppressErrors = false
): ActionCreator<Promise<void>> => {
  if (!code) return

  return async (dispatch, getState) => {
    try {
      const {
        basket: { items, selectedDeliveryDate: selectedDelivery },
      } = getState() as RootState

      const recipes = items.map(({ id, portions }) => {
        return { recipe_id: id, portions }
      })

      const vouchersDiscountPreviewData = {
        code_string: code,
        delivery_date: selectedDelivery,
        recipe_contents: recipes,
      }

      const POST = new PaymentsOnboardingOrderPreview(
        vouchersDiscountPreviewData
      )

      const handleSuccess = () => {
        const {
          code_string: voucherCode,
          recipe_discount_amount: responseDiscountAmount,
          recipe_purchase_value: responseRecipeTotal,
          recipe_external_payment_amount: responseDiscountedPrice,
          recipe_shipping_amount: responseShippingPrice,
          recipe_standard_shipping_amount: responseStandardShippingPrice,
        } = POST

        const discountAmount = parseFloat(responseDiscountAmount)
        const recipeTotal = parseFloat(responseRecipeTotal)
        const discountedPrice = parseFloat(responseDiscountedPrice)
        const shippingPrice = parseFloat(responseShippingPrice)
        const standardShippingPrice = parseFloat(responseStandardShippingPrice)

        /* add name to store */
        dispatch(setVoucherCode(voucherCode))
        /* make price calculations and add discount info amount to store */
        dispatch(
          applyDiscount({ discountAmount, recipeTotal, discountedPrice })
        )
        dispatch(
          setBasketShippingPrice({ shippingPrice, standardShippingPrice })
        )
      }

      const handleError = () => {
        logEvent({
          category: ANALYTICS_CATEGORIES.onboarding,
          action: 'InvalidVoucherCode',
          label: 'Payment Details',
        })

        const errorCode = get(POST, 'errors.code_string.rawPayload.meta.code')

        return Promise.reject(errorCode)
      }

      const success = await POST.save()

      if (success) {
        handleSuccess()
      } else {
        await handleError()
      }
    } catch (error) {
      errorHandler(error, { suppress: suppressErrors })
    }
  }
}

export const applyPostLoginDiscounts = (): ActionCreator<void> => {
  return async (dispatch, getState) => {
    const {
      basket: { items, total, selectedDeliveryDate: selectedDelivery },
    } = getState() as RootState

    const recipeContents = items.map(({ id, portions, price }) => {
      return { recipe_id: id, portions, price }
    })

    // Customers with existing accounts are not eligible for voucher codes,
    // so we remove them before fetching account-assigned discounts and credits
    dispatch(unsetVoucherCode())
    dispatch(revokeDiscount())

    const customerId = getCustomerId()

    try {
      const response = await postCombinedOrderPreview({
        customerId,
        deliveryDate: selectedDelivery,
        recipePurchaseValue: total,
        shopPurchaseValue: null,
        recipeContents,
      })

      dispatch(
        postLoginApplyDiscount({
          creditBalanceAmount: response.recipe.creditBalanceAmount,
          discountAmount: response.recipe.discountAmount,
          paymentAmount: response.recipe.externalPaymentAmount,
        })
      )
    } catch (err) {
      errorHandler(err)
    }
  }
}

/*
  will populate the basket in redux store (items, itemIds etc.)
  by checking the selected recipeIds
*/
export const repopulateBasket = (): ActionCreator<void> => {
  return async (dispatch, getState) => {
    const {
      recipes: { allRecipes },
      basket: { itemIds, savedVoucherCode },
    } = getState() as RootState

    const customerId = getCustomerId()

    dispatch(resetItems())

    // Remove any recipes if they aren't visible
    const basketItems = itemIds
      .map((id) => allRecipes.find((recipe) => id === recipe.id))
      .filter(Boolean)

    dispatch(addItems(basketItems))

    if (customerId) {
      await dispatch(applyPostLoginDiscounts())
    } else if (savedVoucherCode) {
      await dispatch(applyVoucher(savedVoucherCode))
    }
  }
}
