import React, { ReactElement, useState, useEffect } from 'react'
import { Swiper, SwiperClass, SwiperProps } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import { CarouselButton } from './CarouselButton'
import { Flex } from '../layout'
import {
  CarouselProvider,
  useCarouselContext,
} from './context/useCarouselContext'

export type TCarouselProps = {
  onNextButtonClick?: () => void
  onPreviousButtonClick?: () => void
  onTouchEndEvent?: () => void
  pageSize?: number
  showButtonsOnIndexViewed?: boolean
  slides: ReactElement[]
  testIds?: {
    nextSlideButton: string
    previousSlideButton: string
    carouselContainer?: string
  }
}

/* This component does not contain a unit test 
It's basically impossible mocking swiper so we should be covering our carousel
uses in our integration tests
*/

export const Carousel = ({
  onNextButtonClick = () => null,
  onPreviousButtonClick = () => null,
  onTouchEndEvent = () => null,
  pageSize,
  showButtonsOnIndexViewed = false,
  slides = [],
  testIds,
  ...props
}: TCarouselProps &
  Omit<
    SwiperProps,
    'onSlideChangeTransitionEnd' | 'onSwiper'
  >): ReactElement => {
  const [showingPreviousSlideButton, setShowingPreviousSlideButton] =
    useState(false)
  const [showingNextSlideButton, setShowingNextSlideButton] = useState(false)

  // This may not make a whole lot of sense. ProductCarousel needs to have access to swiper instance
  // in order to slide to correct image on click
  const { swiperInstance, setSwiperInstance } = useCarouselContext()

  const handleSlideChange = (swiper: SwiperType) => {
    const showNextCheck = pageSize
      ? swiper.realIndex * pageSize < slides.length - 1
      : swiper.realIndex < slides.length - 1

    setShowingPreviousSlideButton(swiper.realIndex > 0)
    setShowingNextSlideButton(showNextCheck)
  }

  const defaultSwiperProps: Pick<
    SwiperProps,
    'keyboard' | 'slidesPerView' | 'speed'
  > = {
    keyboard: true,
    slidesPerView: 'auto',
    speed: 400,
  }

  useEffect(() => {
    if (showButtonsOnIndexViewed && slides.length >= 1) {
      setShowingNextSlideButton(true)
    }
  }, [showButtonsOnIndexViewed, slides.length])

  return (
    <CarouselProvider>
      <Flex alignItems="center" position="relative" overflow="hidden">
        {showingPreviousSlideButton && (
          <CarouselButton
            aria-label="Show previous slide"
            data-testid={testIds ? testIds.previousSlideButton : null}
            direction="previous"
            onClick={() => {
              swiperInstance.slidePrev()
              onPreviousButtonClick()
            }}
          />
        )}
        <Swiper
          {...defaultSwiperProps}
          {...props}
          data-testid={testIds?.carouselContainer || 'carousel'}
          onSlideChange={(swiper: SwiperClass) =>
            showButtonsOnIndexViewed && handleSlideChange(swiper)
          }
          onSwiper={setSwiperInstance}
          onTouchEnd={onTouchEndEvent}
        >
          {slides}
        </Swiper>
        {showingNextSlideButton && (
          <CarouselButton
            aria-label="Show next slide"
            data-testid={testIds ? testIds.nextSlideButton : null}
            direction="next"
            onClick={() => {
              swiperInstance.slideNext()
              onNextButtonClick()
            }}
          />
        )}
      </Flex>
    </CarouselProvider>
  )
}
