import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { Icon } from '../Icon'
import { theme } from '../system'

export type TCarouselButtonProps = {
  direction: 'previous' | 'next'
  onClick: () => void
}

const SlideButton = styled.button<{
  direction: TCarouselButtonProps['direction']
}>`
  background-color: ${theme.colors.white};
  padding: ${theme.space[6]};
  position: absolute;
  right: ${({ direction }) => direction === 'next' && 0};
  border-radius: ${theme.space[20]};
  z-index: 10;
  transform: ${({ direction }) =>
    direction === 'next' ? 'rotate(-90deg)' : 'rotate(90deg)'};
  border-radius: 20px 20px 0 0;
`

const StyledIcon = styled(Icon)`
  transform: ${({ type }) =>
    type === 'chevron-left' ? 'rotate(-90deg)' : 'rotate(90deg)'};
`

export const CarouselButton = ({
  direction,
  onClick,
  ...props
}: TCarouselButtonProps): ReactElement => {
  return (
    <SlideButton direction={direction} onClick={onClick} {...props}>
      <StyledIcon
        type={direction === 'next' ? 'chevron-right' : 'chevron-left'}
        color="aubergine"
        size={20}
        data-testid="carousel-button-icon"
      />
    </SlideButton>
  )
}
