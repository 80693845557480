import React, { FunctionComponent } from 'react'

import { Box } from '@mindfulchefuk/design-system/layout/Box'
import { Icon } from '@mindfulchefuk/design-system/Icon'
import { ResetButton } from '@mindfulchefuk/design-system/Button'
import { Flex } from '@mindfulchefuk/design-system/layout/Flex'
import { displayDixaMessenger } from '@mindfulchefuk/features/Dixa/displayDixaMessenger'

export type ModalHeaderProps = {
  closeModal?: () => void
  goBack?: () => void
}

export const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
  goBack,
  closeModal,
  children,
}) => (
  <Flex
    align="center"
    borderBottomWidth={1}
    borderColor="pepper-100"
    data-testid="modal-header"
    minHeight={56}
    position="relative"
    pr={64}
    px={24}
    py={12}
    w="100%"
  >
    {goBack && (
      <ResetButton
        onClick={goBack}
        data-testid="modal-back-button"
        display="flex"
        type="button"
      >
        <Icon type="arrow-left" size={28} mr={14} color="aubergine" />
      </ResetButton>
    )}
    {children}
    {closeModal && (
      <Box position="absolute" right={24}>
        <ResetButton
          onClick={() => {
            displayDixaMessenger(true)
            closeModal()
          }}
          data-testid="modal-close-button"
          display="flex"
          type="button"
        >
          <Icon type="cross" size={20} color="aubergine" />
        </ResetButton>
      </Box>
    )}
  </Flex>
)
