/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TRecipeFilter, TRecipeFiltersState } from './types'

const initialState: TRecipeFiltersState = {
  mealPlan: null,
  excludedFoodGroups: null,
  portionCount: null,
  sortedBy: null,
}

const recipeFilterSlice = createSlice({
  name: 'recipeFilters',
  initialState,
  reducers: {
    setRecipeFilter: <F extends TRecipeFilter>(
      state: TRecipeFiltersState,
      action: PayloadAction<{ filter: F; value: TRecipeFiltersState[F] }>
    ) => {
      state[action.payload.filter] = action.payload.value
    },
    resetFilters: () => initialState,
  },
})

export const { resetFilters, setRecipeFilter } = recipeFilterSlice.actions
export const recipeFiltersReducer = recipeFilterSlice.reducer
