import { createReducer } from '@reduxjs/toolkit'

import {
  BASKET_CLEAR_LAST_ADDED_RECIPE,
  BASKET_DELIVERY_DATE_SET,
  BASKET_DISCOUNT_APPLY,
  BASKET_DISCOUNT_REVOKE,
  BASKET_ITEM_ADD,
  BASKET_ITEM_REMOVE,
  BASKET_ITEMS_ADD,
  BASKET_ITEMS_RESET,
  BASKET_MEAL_PLAN_SET,
  BASKET_PORTION_COUNT_SET,
  BASKET_POST_LOGIN_DISCOUNT_APPLY,
  BASKET_RESET,
  BASKET_SET_SHIPPING_PRICE,
  BASKET_VOUCHER_CODE_SET,
  BASKET_VOUCHER_CODE_UNSET,
  SET_BASKET_LOADING,
} from '@mindfulchefuk/actions/basketActions'
import arrayRemoveLastMatch from '@mindfulchefuk/utils/array/arrayRemoveLastMatch'
import getTotal from '@mindfulchefuk/utils/getTotal'
import { BasketState } from '@mindfulchefuk/features/Basket'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'

const initialState: BasketState = {
  items: [],
  itemIds: [],
  portionCount: 2,
  selectedMealPlan: 'Balanced',
  total: 0,
  savedVoucherCode: '',
  discountAmount: 0,
  totalWithDiscount: 0,
  creditBalanceAmount: 0,
  recipeShippingPrice: 0,
  standardRecipeShippingPrice: null,
  discountApplied: false,
  creditApplied: false,
  isBasketLoading: false,
  lastAddedRecipeId: '',
  selectedDeliveryDate: '',
}

const basketReducer = createReducer(initialState, {
  [BASKET_DELIVERY_DATE_SET]: (state, action): BasketState => ({
    ...state,
    selectedDeliveryDate: action.payload,
  }),
  [BASKET_ITEM_ADD]: (state, action): BasketState => {
    const updatedItems = [...state.items, action.payload]

    return {
      ...state,
      items: updatedItems,
      itemIds: [...state.itemIds, action.payload.id],
      total: getTotal(updatedItems.map((item) => item.price)),
      lastAddedRecipeId: action.payload.id,
    }
  },

  [BASKET_ITEMS_ADD]: (state, action) => {
    const updatedItems = [...state.items, ...action.payload]
    const updatedItemIds = action.payload.map((item: Recipe) => item.id)

    return {
      ...state,
      items: updatedItems,
      itemIds: [...state.itemIds, ...updatedItemIds],
      total: getTotal(updatedItems.map((item) => item.price)),
    }
  },

  [BASKET_ITEM_REMOVE]: (state, action) => {
    const updatedItems = arrayRemoveLastMatch(
      state.items,
      action.payload.id,
      (item, id) => item.id === id
    )
    const updatedItemIds = arrayRemoveLastMatch(
      state.itemIds,
      action.payload.id
    )
    return {
      ...state,
      items: updatedItems,
      itemIds: updatedItemIds,
      total: getTotal(updatedItems.map((item: Recipe) => item.price)),
    }
  },

  [BASKET_ITEMS_RESET]: (state) => ({
    ...state,
    items: [],
    itemIds: [],
    total: 0,
    totalWithDiscount: 0,
    discountApplied: false,
    creditApplied: false,
  }),

  [BASKET_PORTION_COUNT_SET]: (state, action) => {
    const portionCount = action.payload

    return {
      ...state,
      portionCount,
    }
  },

  [BASKET_MEAL_PLAN_SET]: (state, action) => ({
    ...state,
    selectedMealPlan: action.payload,
  }),

  [BASKET_SET_SHIPPING_PRICE]: (state, action) => ({
    ...state,
    recipeShippingPrice: action.payload.shippingPrice,
    standardRecipeShippingPrice: action.payload.standardShippingPrice,
  }),

  [BASKET_VOUCHER_CODE_SET]: (state, action) => ({
    ...state,
    savedVoucherCode: action.payload,
  }),

  [BASKET_VOUCHER_CODE_UNSET]: (state) => ({
    ...state,
    savedVoucherCode: initialState.savedVoucherCode,
  }),

  [BASKET_DISCOUNT_APPLY]: (state, action) => ({
    ...state,
    discountAmount: action.payload.discountAmount,
    discountApplied: !!action.payload.discountAmount,
    total: action.payload.recipeTotal,
    totalWithDiscount: state.total ? action.payload.discountedPrice : 0,
  }),

  [BASKET_POST_LOGIN_DISCOUNT_APPLY]: (state, action) => ({
    ...state,
    creditBalanceAmount: action.payload.creditBalanceAmount,
    discountAmount: action.payload.discountAmount,
    discountApplied: !!action.payload.discountAmount,
    creditApplied: !!action.payload.creditBalanceAmount,
    totalWithDiscount: action.payload.paymentAmount,
  }),

  [BASKET_DISCOUNT_REVOKE]: (state) => ({
    ...state,
    discountAmount: 0,
    discountApplied: false,
    creditApplied: false,
    totalWithDiscount: 0,
  }),

  [BASKET_RESET]: () => ({
    ...initialState,
  }),

  [BASKET_CLEAR_LAST_ADDED_RECIPE]: (state) => ({
    ...state,
    lastAddedRecipeId: '',
  }),

  [SET_BASKET_LOADING]: (state, action) => ({
    ...state,
    isBasketLoading: action.payload,
  }),
})

export default basketReducer
