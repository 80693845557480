import { RecipesFeedback, RecipesRecipe } from '@mindfulchefuk/api-client'
import { foodGroup as FoodGroup } from '@mindfulchefuk/config/mealPlanConfig'
import { PortionCount } from '@mindfulchefuk/config/portionCountConfig'
import {
  APIFeedback,
  APIRecipe,
  APIRecipeComponent,
  APIRecipeImage,
  APIRecipeIngredient,
  APIRecipeNutrition,
  APIRecipePortionComponent,
  Ingredient,
  JustRecipe,
  Macros,
  MacrosByPortionSize,
  RecipeFeedback,
  RecipeInstructions,
  RecipeStep,
  TAPITag,
  TRecipeClaim,
} from '@mindfulchefuk/features/Recipes/interfaces'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import sortBy from '@mindfulchefuk/utils/sortBy'

export type TGetRecipesParams = {
  recipeIds: string[]
  portionCount: PortionCount
}

export const getRecipes = async ({
  recipeIds,
  portionCount,
}: TGetRecipesParams): Promise<JustRecipe[]> => {
  if (!recipeIds.length) {
    return []
  }

  const select = {
    ingredients: ['name', 'food_group', 'packing_location'],
    recipes: [
      'allergens',
      'possible_allergens',
      'average_rating',
      'cuisine',
      'description',
      'key_ingredient_description',
      'title',
      'carbon_rating',
      'spiciness_level',
      'before_you_start',
    ],
    steps: ['duration_minutes'],
  }
  const merge: { feedbacks?: typeof RecipesFeedback } = {}
  const includes = [
    'images',
    'ingredients.allergen_presences',
    'steps',
    `macros_per_person_for_${portionCount}_portion`,
    `macros_per_100g_for_${portionCount}_portion`,
    'recipe_portion_components.component',
    'components.ingredient',
    'tags',
  ]

  const where = {
    id: recipeIds,
    recipe_portion_components: { portion_count_per_meal: portionCount },
    allergen_presences: { contains: ['yes', 'maybe'] },
  }

  const customerId = getCustomerId()

  if (customerId) {
    includes.push('feedbacks', 'feedbacks.feedback_reasons')

    merge.feedbacks = RecipesFeedback.select([
      'delivery_id',
      'rating',
      'description',
      'created_at',
      'feedback_reasons',
    ])
      .where({ customer_id: { eq: customerId } })
      .order({ created_at: 'desc' })
  }

  const { data: recipesData }: { data: APIRecipe[] } =
    await RecipesRecipe.select(select)
      .includes(includes)
      .merge(merge)
      .where(where)
      .extraParams({ pagination_links: false })
      .all()

  const recipes = recipesData.map((apiRecipe) => {
    const { id } = apiRecipe
    try {
      const [perPersonMacros, per100gMacros] =
        recipeTransformers.getMacrosKeys(portionCount)

      const {
        cuisine,
        description,
        images,
        allergens,
        possible_allergens: possibleAllergens,
        badges,
        ingredients,
        steps,
        average_rating: averageRating,
        key_ingredient_description: keyIngredient,
        recipe_portion_components: recipePortionComponents,
        carbon_rating: carbonRating,
        spiciness_level: spicinessLevel,
        components,
        cook_along_video_url: videoUrl,
        [perPersonMacros]: macrosPerPerson,
        [per100gMacros]: macrosPer100g,
        title,
        feedbacks: recipeFeedbacks = [],
        before_you_start: beforeYouStart,
        tags,
      } = apiRecipe

      const cookingTime = apiRecipe.cookingTime()
      const prepTime = apiRecipe.prepTime()
      const foodGroup = apiRecipe.foodGroup() as FoodGroup

      const { imageUrl, secondaryImageUrls } =
        recipeTransformers.getImages(images)

      return {
        allergens,
        possibleAllergens,
        averageRating,
        badges,
        carbonRating,
        cookingTime,
        cuisine,
        description,
        foodGroup,
        id,
        imageUrl,
        keyIngredient,
        portions: portionCount,
        prepTime,
        secondaryImageUrls,
        spicinessLevel,
        title,
        videoUrl,
        // Mapped fields
        cookingInstructions: recipeTransformers.getCookingInstructions(steps),
        feedback: recipeTransformers.getFeedback(recipeFeedbacks),
        ingredients: recipeTransformers.getIngredients(ingredients),
        macros: recipeTransformers.getMacros(macrosPerPerson, macrosPer100g),
        quantifiedIngredients: recipeTransformers.getQuantifiedIngredients(
          components,
          recipePortionComponents
        ),
        beforeYouStart,
        claims: recipeTransformers.getClaims(tags || []),
      }
    } catch (err) {
      console.error(
        `Recipe ${id} has malformed or missing data and was not rendered`,
        err
      )
      return null
    }
  })

  return recipes.filter(Boolean)
}

export const recipeTransformers = {
  getCookingInstructions: (steps: RecipeStep[]) => {
    const recipeInstructions: RecipeInstructions[] = steps
      .sort((a, b) => sortBy(a.position, b.position))
      .map((step) => ({
        title: step?.title,
        description: step.description,
      }))
    return recipeInstructions
  },

  getFeedback: (apiFeedback: APIFeedback[]) => {
    const feedbacks: RecipeFeedback[] = apiFeedback.map((feedback) => ({
      id: feedback.id,
      rating: feedback.rating,
      description: feedback.description,
      createdAt: feedback.created_at,
      feedbackReason: feedback.feedback_reasons[0]
        ? {
            id: feedback.feedback_reasons[0].id,
            name: feedback.feedback_reasons[0].name,
          }
        : undefined,
    }))

    return feedbacks[0]
  },

  getImages: (apiImages: APIRecipeImage[]) => {
    const imageUrls = apiImages.map((image) => image.attributes.url)
    const primaryImageIndex = apiImages.findIndex((i) => i.attributes.primary)

    return {
      imageUrl: imageUrls[primaryImageIndex] ?? null,
      secondaryImageUrls: imageUrls.filter(
        (_, index) => index !== primaryImageIndex
      ),
    }
  },

  getIngredients: (apiIngredients: APIRecipeIngredient[]): Ingredient[] => {
    return apiIngredients.map((ingredient) => ({
      id: ingredient.id,
      name: ingredient.name,
    }))
  },

  getMacrosKeys: (portionCount: number) => {
    const perPersonMacros = `macros_per_person_for_${portionCount}_portion` as
      | 'macros_per_person_for_1_portion'
      | 'macros_per_person_for_2_portion'
      | 'macros_per_person_for_4_portion'

    const per100gMacros = `macros_per_100g_for_${portionCount}_portion` as
      | 'macros_per_100g_for_1_portion'
      | 'macros_per_100g_for_2_portion'
      | 'macros_per_100g_for_4_portion'

    return [perPersonMacros, per100gMacros]
  },

  getMacros: (
    macrosPerPerson: APIRecipeNutrition,
    macrosPer100g: APIRecipeNutrition
  ) => {
    const macros: MacrosByPortionSize = {
      macrosPerPerson: {} as Macros,
      macrosPer100g: {} as Macros,
    }

    Object.entries(macrosPerPerson.attributes).forEach(
      ([key, macrosPerPersonValue]) => {
        const typedKey = key as keyof APIRecipeNutrition['attributes']
        const macroPer100gValue = macrosPer100g.attributes[typedKey]

        const camelCaseKey =
          key === 'saturated_fat' ? 'saturatedFat' : (key as keyof Macros)

        macros.macrosPerPerson[camelCaseKey] = parseFloat(macrosPerPersonValue)

        macros.macrosPer100g[camelCaseKey] = parseFloat(macroPer100gValue)
      }
    )
    return macros
  },

  getQuantifiedIngredients: (
    components: APIRecipeComponent[],
    recipePortionComponents: APIRecipePortionComponent[]
  ) => {
    return recipePortionComponents.map((ingredient) => {
      const componentId = ingredient.relationships.component.id
      const {
        ingredient: {
          description: ingredientDescrtiption,
          name: ingredientName,
          allergen_presences: allergenPresences,
        },
        packing_location: packingLocation,
      } = components.find((component) => component.id === componentId)

      const { ingredientAllergens, ingredientPossibleAllergens } =
        allergenPresences.reduce(
          (acc, allergenPresence) => {
            if (allergenPresence.contains === 'yes') {
              acc.ingredientAllergens.push(allergenPresence.allergen)
            } else if (allergenPresence.contains === 'maybe') {
              acc.ingredientPossibleAllergens.push(allergenPresence.allergen)
            }
            return acc
          },
          { ingredientAllergens: [], ingredientPossibleAllergens: [] }
        )

      return {
        id: ingredient.id,
        componentId,
        name: ingredientName,
        displayTitle: ingredient.display_title,
        description: ingredientDescrtiption,
        allergens: ingredientAllergens,
        possibleAllergens: ingredientPossibleAllergens,
        packingLocation,
      }
    })
  },
  getClaims: (tags: TAPITag[]): TRecipeClaim[] => {
    const mapped = tags.map((tag: TAPITag) => {
      return {
        backgroundColor: tag.background_colour ?? '#FFF',
        description: tag.description,
        name: tag.name,
        sortOrder: tag.sort_order ?? 0,
        textColor: tag.text_colour,
      }
    })

    // The top three tags from the api should be displayed in ascending value sort order
    const top3 = mapped.slice(0, 3).sort((a, b) => a.sortOrder - b.sortOrder)
    const rest = mapped.slice(3, mapped.length)

    return [...top3, ...rest]
  },
}
