/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  ReactNode,
  useContext,
  ReactElement,
  useState,
  useMemo,
} from 'react'
import SwiperCore from 'swiper'

export type TCarouselContextValues = {
  swiperInstance: null | SwiperCore
  setSwiperInstance: (swiper: SwiperCore) => void
}

type TCarouselProviderProps = {
  children: ReactNode
}

const defaultContextValues: TCarouselContextValues = {
  swiperInstance: null,
  setSwiperInstance: () => {},
}

const CarouselContext =
  React.createContext<TCarouselContextValues>(defaultContextValues)

export const useCarouselContext = () => useContext(CarouselContext)

export const CarouselProvider = ({
  children,
}: TCarouselProviderProps): ReactElement => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>()

  const contextValue = useMemo(
    () => ({ swiperInstance, setSwiperInstance }),
    [swiperInstance]
  )
  return (
    <CarouselContext.Provider value={contextValue}>
      {children}
    </CarouselContext.Provider>
  )
}
